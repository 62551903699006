export const informes = [
    {
        id: 1,
        name: 'Informe Simple',
        slug: 'simple',
        price: 2990,
        descriptions: [
            'Información general de la empresa',
            'Puntaje de riesgo',
            'Deuda bancaria (CMF)',
            'Demandas poder judicial',
            'Publicaciones en boletín Consursal',
            'Empresa en un día'
        ],
    },
    {
        id: 2,
        name: 'Informe Premium',
        slug: 'premium',
        price: 23990, 
        descriptions: [
            'Información general de la empresa',
            'Puntaje de riesgo',
            'Deuda bancaria (CMF)',
            'Demandas poder judicial',
            'Publicaciones en boletín Consursal',
            'Empresa en un día',
            'Ventas',
            'Notas de crédito',
            'Historial clientes y proveedores',
            'Indicadores financieros'
        ],
    }
]