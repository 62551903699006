import { createRouter, createWebHistory } from 'vue-router'
import { Layout } from '../ui/layout'
import { PurchaseEvent } from '@/purchase-event'
import { NotFound } from '@/ui/pages'

const routes = [
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: '',
        redirect: 'compra-informes'
      },
      {
        path: 'compra-informes/:id?',
        component: PurchaseEvent,
        beforeEnter: (to, from, next) => {
          const { id } = to.params;
          if (!id || (id !== 'simple' && id !== 'premium')) {
            if (to.path !== '/compra-informes') {
              next({ path: '/compra-informes' });
            } else {
              next(); 
            }
          } else {
            next();
          }
        }
      },
      {
        path: 'compra-informes/:id/:token',
        component: PurchaseEvent,
        beforeEnter: (to, from, next) => {
          const { id } = to.params;
          if (id === 'simple' || id === 'premium') {
            next();
          } else {
            next({ path: '/compra-informes' });
          }
        }
      },
      {
        path: '/:catchAll(.*)', 
        component: NotFound, 
      },
    ],
  },
  {
      path: "/enrolamiento",
      name: "enrolamiento-empresa",
      component: () => import("@/landings/pages/LandingEnrolamiento.vue"),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
